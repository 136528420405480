<template>
    <modal-lateral ref="modalFiltroPasarelaMercadoPago" titulo="Filtro">
        <div class="custom-scroll overflow-auto" style="height:calc(100vh - 103px)">
            <div class="row mx-0 justify-center">
                <div class="col-11 mt-3">
                    <p class="pl-3 text-general f-12">Fecha de pago</p>
                    <el-date-picker
					size="small"
                    v-model="fechas"
                    type="daterange"
                    range-separator="-"
                    class="w-100"
                    start-placeholder="Fecha Inicio"
                    end-placeholder="Fecha Fin"
                    />
                </div>
                <div class="col-11 mt-4">
                    <p class="pl-3 text-general f-12">Cedis</p>
                    <el-select v-model="idCedis" class="w-100" size="small">
                        <el-option
                        v-for="item in selectCedis"
                        :key="item.id"
                        :label="item.nombre"
                        :value="item.id"
                        />
                    </el-select>
                </div>
                <div class="col-11 mt-4">
                    <p class="pl-3 text-general f-12">Medio de pago</p>
                    <el-select v-model="medioPago" class="w-100" size="small">
                        <el-option
                        v-for="(item,key) in mediosPagos"
                        :key="key"
                        :label="item.label"
                        :value="item.value"
                        />
                    </el-select>
                </div>
                <div class="col-11 mt-3">
                    <div class="row mx-0 mb-3">
                        <div class="col-12 bg-light-f5 cr-pointer d-middle border br-8 " style="height:32px;">
                            <span class="f-14 text-general">Estado</span>
							<div class="f-14 wh-20 rounded-circle bg-dark ml-auto text-white d-middle-center">{{checkEstado.length}}</div>
                        </div>
                        <div class="col-12 mt-2 pl-3">
                            <el-checkbox-group v-model="checkEstado">
                                <el-checkbox label="approved" class="w-100 f-16 text-general check-dark mt-2">
                                    Aprobado
                                </el-checkbox>
                                <el-checkbox label="rejected" class="w-100 f-16 text-general check-dark mt-2">
                                    Rechazado
                                </el-checkbox>
                                <el-checkbox label="pending" class="w-100 f-16 text-general check-dark mt-2">
                                    Pendiente
                                </el-checkbox>
                            </el-checkbox-group>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row mx-0">
            <div class="col">
                <div class="bg-white cr-pointer text-general border-black d-middle-center br-8" style="height:32px;" @click="limpiarFiltro">
                    Limpiar
                </div>
            </div>
            <div class="col">
                <div class="bg-general cr-pointer text-white br-8 d-middle-center" style="height:32px;" @click="filtrar()">
                    Filtrar
                </div>
            </div>
        </div>
    </modal-lateral>
</template>

<script>
import Pagos from "~/services/pasarela-tesoreria/mercado-pago"
export default {
    props:{
        idCuenta:{
            type:[Number,String],
            default:0
        }
    },
    data(){
        return{
            fechas:[],
            checkTipo:[1,2,3],
            checkEstado:[],
            idCedis:'',
            medioPago:'',
            mediosPagos:[
				{
					label: 'Transferencia bancaria',
					value: 'bank_transfer'
				},
				{
					label: 'Tarjeta crédito',
					value: 'credit_card'
				},
			]

        }
    },
    computed:{
        selectCedis(){
            return this.cedis_calculo.filter(o=>o.id != 0)
        }
    },
    methods: {
        toggle(){
            this.$refs.modalFiltroPasarelaMercadoPago.toggle();
        },
		limpiarFiltro(){
			this.medioPago = '';
			this.checkEstado = [];
			this.idCedis = '';
			this.fechas = []
		},
        async filtrar(){

            const params = {
                fechas: this.fechas,
                checkEstado: this.checkEstado,
                idCedis: this.idCedis ?? 0,
                medioPago: this.medioPago
            }

            const {data} = await Pagos.getPagos(this.idCuenta, params)
            this.$emit('filtrar',data.data)
            this.$refs.modalFiltroPasarelaMercadoPago.toggle();

        }
    }
}
</script>
